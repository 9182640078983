@import './opensans.less';

html,
body,
.conviva-main {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;

  &.dark {
    background-color: #1f1e1f;
  }
}

.dark-alpha-theme {
  filter: invert(90%) hue-rotate(180deg) saturate(120%) brightness(95%);
  // restore color critical components to original color by inverting again
  .color-critical,
  img {
    filter: invert(100%) hue-rotate(180deg) saturate(100%) brightness(100%);
  }
}

body {
  font-family: 'Open Sans', sans-serif !important;

  // dark theme
  &.alpha-theme:not(img):not(video) {
    .conviva-main {
      background-color: #1f1e1f;
    }

    // filter creates a containing block for absolute and fixed descendants
    // filter should be applied at the component level to avoid position issues
    #__nav-app-root__ {
      header,
      > div {
        .dark-alpha-theme();
      }
    }

    .conviva-app-loader > div[data-name='pulse4Loader'],
    .conviva-app-loader > div[data-name='execReport'],
    .conviva-app-loader > div[data-name='loadingError'],
    .exec-email-modal,
    .apphub-sub-menu-wrapper,
    .apphub-select-dropdown,
    .apphub-tooltip,
    .apphub-popover-content,
    .apphub-dropdown,
    .apphub-drawer {
      .dark-alpha-theme();
    }
  }
}

.conviva-main {
  display: flex;
  flex-direction: column;

  > .conviva-content {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    padding: 0 5px;
    flex-direction: row-reverse;
    z-index: 0;
    contain: layout;

    .conviva-app-loader {
      flex-grow: 1;
      overflow: hidden;

      > div {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: auto;
      }
    }
  }
}
